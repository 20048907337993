import React from 'react';

const EmailSendingIcon = (props) => (
  <svg {...props} width="58" height="47" viewBox="0 0 58 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M52.8179 20.9752C52.6186 20.9752 52.4193 20.9752 52.22 20.781C51.8214 20.5868 51.4227 20.3926 51.0241 20.3926H42.8523C42.2543 20.3926 41.8557 20.0042 41.8557 19.4215C41.8557 18.8389 42.2543 18.4504 42.8523 18.4504H51.0241C52.0207 18.4504 52.8179 18.8389 53.4159 19.4215C53.8145 19.8099 53.8145 20.3926 53.4159 20.781C53.2165 20.781 53.0172 20.9752 52.8179 20.9752Z"
      fill="#0486FE"
    />
    <path
      d="M16.3437 20.9752C16.1444 20.9752 15.7457 20.9752 15.7457 20.781C15.3471 20.3926 15.3471 19.8099 15.7457 19.4215C16.3437 18.8389 17.3402 18.4504 18.1375 18.4504H28.5018C29.0997 18.4504 29.4983 18.8389 29.4983 19.4215C29.4983 20.0042 29.0997 20.3926 28.5018 20.3926H18.1375C17.7389 20.3926 17.3402 20.5868 16.9416 20.781C16.9416 20.781 16.543 20.9752 16.3437 20.9752Z"
      fill="#0486FE"
    />
    <path
      d="M43.8493 0H20.729V24.0826L32.4885 35.3471L33.0864 35.9297H36.0761L36.674 35.3471L48.4335 24.0826V4.46694L43.8493 0Z"
      fill="#CDE7FF"
    />
    <path
      d="M53.4162 19.4213C53.0175 19.0328 52.6189 19.0328 52.2203 19.2271C52.2203 19.2271 52.2203 19.2271 52.021 19.4213L35.8767 34.7642L35.6773 34.9585H33.4849L33.0863 34.57L17.1413 19.2271C17.1413 19.2271 17.1413 19.2271 16.942 19.0328C16.5433 19.0328 15.9454 19.0328 15.7461 19.4213C15.1481 20.0039 14.7495 20.975 14.7495 21.9461V43.8923C14.7495 44.8634 15.1481 45.8345 15.9454 46.4171C16.5433 46.9998 17.3406 47.194 18.3371 47.194H51.0244C51.8217 47.194 52.6189 46.8056 53.4162 46.4171C54.2134 45.8345 54.612 44.8634 54.612 43.8923V21.7518C54.612 20.7808 54.2134 20.0039 53.4162 19.4213Z"
      fill="#0486FE"
    />
    <path
      d="M16.742 44.2809C16.742 44.0867 16.5427 43.8925 16.5427 43.6982V21.752C16.5427 21.5577 16.5427 21.5577 16.5427 21.3635L29.8967 34.1817L16.742 44.2809Z"
      fill="white"
    />
    <path
      d="M18.5359 45.2525L31.4912 35.736L31.8898 36.1244L32.4878 36.707C32.6871 36.9013 32.8864 36.9013 33.0857 36.9013H36.0754C36.2747 36.9013 36.474 36.9013 36.6733 36.707L37.2713 36.1244L37.6699 35.736L50.6252 45.2525H18.5359Z"
      fill="white"
    />
    <path
      d="M52.618 43.6979C52.618 43.8921 52.618 44.0863 52.4186 44.2805L39.0647 34.3756L52.4186 21.5574C52.4186 21.7516 52.4186 21.7516 52.4186 21.9458V43.6979H52.618Z"
      fill="#0486FE"
    />
    <path
      d="M31.2916 9.90443H37.8689C38.4668 9.90443 38.8655 9.516 38.8655 8.93335C38.8655 8.35071 38.4668 7.96228 37.8689 7.96228H31.0923C30.4943 7.96228 30.0957 8.35071 30.0957 8.93335C30.295 9.516 30.6936 9.90443 31.2916 9.90443Z"
      fill="#0486FE"
    />
    <path
      d="M28.3018 15.3429H41.0578C41.6558 15.3429 42.0544 14.9545 42.0544 14.3718C41.8551 13.9834 41.4565 13.595 40.8585 13.595H28.1025C27.5046 13.595 27.106 13.9834 27.106 14.566C27.3053 14.9545 27.7039 15.3429 28.3018 15.3429Z"
      fill="#0486FE"
    />
    <path
      d="M0.996564 15.3429H13.7526C14.3505 15.3429 14.7491 14.9545 14.7491 14.3718C14.5498 13.9834 14.1512 13.595 13.7526 13.595H0.996564C0.398625 13.595 0 13.9834 0 14.566C0 14.9545 0.398625 15.3429 0.996564 15.3429Z"
      fill="#0486FE"
    />
    <path
      d="M28.3027 19.6152H41.0587C41.6567 19.6152 42.0553 19.2268 42.0553 18.6442C42.0553 18.0615 41.6567 17.6731 41.0587 17.6731H28.3027C27.7048 17.6731 27.3062 18.0615 27.3062 18.6442C27.3062 19.2268 27.7048 19.6152 28.3027 19.6152Z"
      fill="#0486FE"
    />
    <path
      d="M52.618 43.6979C52.618 43.8921 52.618 44.0863 52.4186 44.2805L39.0647 34.3756L52.4186 21.5574C52.4186 21.7516 52.4186 21.7516 52.4186 21.9458V43.6979H52.618Z"
      fill="white"
    />
    <path
      d="M6.97606 9.90443H13.7527C14.3506 9.90443 14.7493 9.516 14.7493 8.93335C14.7493 8.35071 14.3506 7.96228 13.7527 7.96228H6.97606C6.37812 8.1565 5.97949 8.54492 5.97949 9.12757C5.97949 9.516 6.37812 9.90443 6.97606 9.90443Z"
      fill="#0486FE"
    />
    <path
      d="M51.8218 12.8185C55.2342 12.8185 58.0005 10.123 58.0005 6.79788C58.0005 3.47276 55.2342 0.777222 51.8218 0.777222C48.4094 0.777222 45.6431 3.47276 45.6431 6.79788C45.6431 10.123 48.4094 12.8185 51.8218 12.8185Z"
      fill="#01DD9B"
    />
    <path
      d="M51.0247 9.12859H50.8254L49.0316 7.38066C48.8323 7.18644 48.8323 6.99223 49.0316 6.99223C49.2309 6.79801 49.4302 6.79801 49.4302 6.99223L50.8254 8.35173L54.2137 5.05008C54.413 4.85587 54.6123 4.85587 54.6123 5.05008C54.8116 5.24429 54.8116 5.43851 54.6123 5.43851L51.0247 8.93438C51.224 9.12859 51.0247 9.12859 51.0247 9.12859Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <defs>
      <clipPath id="clip0">
        <rect width="58" height="47" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmailSendingIcon;
