import React from 'react';
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
  <svg {...props} width="112" height="36" viewBox="0 0 112 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56.5 22.7091H53.5L53 25H50L53.5 11H56.5L60 25H57L56.5 22.7091ZM55.875 20.0364L55.5 18.3818C55.375 17.3636 55.125 15.7091 54.875 14.5636H54.75C54.625 15.7091 54.375 17.3636 54.125 18.3818L54 20.0364H55.875Z"
      fill="#0486FE"
    />
    <path d="M64 11V25H61V11H64Z" fill="#0486FE" />
    <path
      d="M67 21.4386H67.8305C67.9492 23.1579 68.8983 24.1403 70.5593 24.1403C72.2203 24.1403 73.1695 23.0351 73.1695 21.5614C73.1695 18.2456 67.1186 18.1228 67.1186 14.193C67.1186 12.3509 68.5424 11 70.4407 11C72.4576 11 73.7627 12.3509 73.8814 14.4386H72.9322C72.8136 12.7193 71.8644 11.8596 70.4407 11.8596C68.8983 11.8596 67.9492 12.8421 67.9492 14.193C67.9492 17.5088 74 17.5088 74 21.5614C74 23.4035 72.6949 25 70.5593 25C68.4237 25 67.1186 23.6491 67 21.4386Z"
      fill="#2A2B2B"
    />
    <path
      d="M82.3529 21.4364H77.8824L76.9412 25H76L79.5294 11H80.4706L84 25H83.0588L82.3529 21.4364ZM82.1176 20.6727L80.7059 14.9455C80.4706 13.9273 80.2353 12.9091 80.1177 12.2727C80 12.9091 79.7647 13.9273 79.5294 14.9455L78.1176 20.6727H82.1176Z"
      fill="#2A2B2B"
    />
    <path d="M93 24.1091V25H86V11H86.98V24.1091H93Z" fill="#2A2B2B" />
    <path
      d="M102 24.1091V25H95V11H101.868V11.7636H95.9245V17.3636H101.472V18.2545H95.9245V24.1091H102Z"
      fill="#2A2B2B"
    />
    <path
      d="M104 21.4386H104.949C105.085 23.1579 106.169 24.1403 108.068 24.1403C109.966 24.1403 111.051 23.0351 111.051 21.5614C111.051 18.2456 104.136 18.1228 104.136 14.193C104.136 12.3509 105.763 11 107.932 11C110.237 11 111.729 12.3509 111.864 14.4386H110.78C110.644 12.7193 109.559 11.8596 107.932 11.8596C106.169 11.8596 105.085 12.8421 105.085 14.193C105.085 17.5088 112 17.5088 112 21.5614C112 23.4035 110.508 25 108.068 25C105.627 25 104.136 23.6491 104 21.4386Z"
      fill="#2A2B2B"
    />
    <path d="M28.2356 10.3048L32.3223 4.1487L16.3469 0L17.2138 7.36059L28.2356 10.3048Z" fill="#0486FE" />
    <path d="M23.282 17.6655L27.3687 11.3756L17.3376 8.69897L17.9568 13.7845L23.282 17.6655Z" fill="#0486FE" />
    <path d="M4.58203 4.28175L13.9939 10.9732L15.9753 7.89514L15.1084 0.133057L4.58203 4.28175Z" fill="#0486FE" />
    <path d="M29.4734 10.5725L32.9409 11.5093L33.1886 4.95166L29.4734 10.5725Z" fill="#0486FE" />
    <path d="M37.7707 14.0518L34.3032 13.2488L34.6747 17.9328L37.7707 14.0518Z" fill="#0486FE" />
    <path d="M34.4274 6.69141L34.1797 11.7769L38.3903 12.9814L34.4274 6.69141Z" fill="#0486FE" />
    <path
      d="M23.5294 19.405L19.3188 25.8288L24.1486 28.7731L25.2632 29.4422L29.8453 23.8214L23.5294 19.405Z"
      fill="#0486FE"
    />
    <path d="M16.2233 9.76953L14.9849 11.777L16.5948 12.8476L16.2233 9.76953Z" fill="#0486FE" />
    <path d="M0.371338 12.4458L2.35278 19.2711L6.43951 19.8064L0.371338 12.4458Z" fill="#0486FE" />
    <path d="M19.8142 27.5688L24.1486 36.0001L26.0062 35.7324L24.644 30.6469L19.8142 27.5688Z" fill="#0486FE" />
    <path
      d="M30.8358 24.6245L26.1299 30.3792L28.6067 32.6543L34.9225 30.3792L34.7987 27.4349L30.8358 24.6245Z"
      fill="#0486FE"
    />
    <path
      d="M16.8423 14.5874L14.3655 12.8477L7.92578 23.1525L12.2602 26.4982L18.0807 25.5614L16.8423 14.5874Z"
      fill="#0486FE"
    />
    <path d="M34.7988 19.6729L35.418 26.2305L40.0001 22.6171L39.0094 14.5874L34.7988 19.6729Z" fill="#0486FE" />
    <path d="M0 10.1707L8.29729 20.0741L8.42113 20.2079L13.3747 12.0443L3.59137 5.08521L0 10.1707Z" fill="#0486FE" />
    <path
      d="M33.5605 19.2713L33.0651 12.8475L28.7307 11.7769L24.2725 18.3345L30.5883 22.8847L33.5605 19.2713Z"
      fill="#0486FE"
    />
    <path d="M22.5388 18.7356L18.0806 15.5237L19.0713 23.8211L22.5388 18.7356Z" fill="#0486FE" />
    <path d="M33.8084 21.011L31.7031 23.5537L34.1799 25.4273L33.8084 21.011Z" fill="#0486FE" />
  </svg>
);
