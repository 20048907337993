import React from 'react';
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
  <svg {...props} width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.2356 10.3048L32.3223 4.1487L16.3469 0L17.2138 7.36059L28.2356 10.3048Z" fill="#0486FE" />
    <path d="M23.282 17.6655L27.3687 11.3756L17.3376 8.69897L17.9568 13.7845L23.282 17.6655Z" fill="#0486FE" />
    <path d="M4.58203 4.28175L13.9939 10.9732L15.9753 7.89514L15.1084 0.133057L4.58203 4.28175Z" fill="#0486FE" />
    <path d="M29.4734 10.5725L32.9409 11.5093L33.1886 4.95166L29.4734 10.5725Z" fill="#0486FE" />
    <path d="M37.7707 14.0518L34.3032 13.2488L34.6747 17.9328L37.7707 14.0518Z" fill="#0486FE" />
    <path d="M34.4274 6.69141L34.1797 11.7769L38.3903 12.9814L34.4274 6.69141Z" fill="#0486FE" />
    <path
      d="M23.5294 19.405L19.3188 25.8288L24.1486 28.7731L25.2632 29.4422L29.8453 23.8214L23.5294 19.405Z"
      fill="#0486FE"
    />
    <path d="M16.2233 9.76953L14.9849 11.777L16.5948 12.8476L16.2233 9.76953Z" fill="#0486FE" />
    <path d="M0.371338 12.4458L2.35278 19.2711L6.43951 19.8064L0.371338 12.4458Z" fill="#0486FE" />
    <path d="M19.8142 27.5688L24.1486 36.0001L26.0062 35.7324L24.644 30.6469L19.8142 27.5688Z" fill="#0486FE" />
    <path
      d="M30.8358 24.6245L26.1299 30.3792L28.6067 32.6543L34.9225 30.3792L34.7987 27.4349L30.8358 24.6245Z"
      fill="#0486FE"
    />
    <path
      d="M16.8423 14.5874L14.3655 12.8477L7.92578 23.1525L12.2602 26.4982L18.0807 25.5614L16.8423 14.5874Z"
      fill="#0486FE"
    />
    <path d="M34.7988 19.6729L35.418 26.2305L40.0001 22.6171L39.0094 14.5874L34.7988 19.6729Z" fill="#0486FE" />
    <path d="M0 10.1707L8.29729 20.0741L8.42113 20.2079L13.3747 12.0443L3.59137 5.08521L0 10.1707Z" fill="#0486FE" />
    <path
      d="M33.5605 19.2713L33.0651 12.8475L28.7307 11.7769L24.2725 18.3345L30.5883 22.8847L33.5605 19.2713Z"
      fill="#0486FE"
    />
    <path d="M22.5388 18.7356L18.0806 15.5237L19.0713 23.8211L22.5388 18.7356Z" fill="#0486FE" />
    <path d="M33.8084 21.011L31.7031 23.5537L34.1799 25.4273L33.8084 21.011Z" fill="#0486FE" />
  </svg>
);
