import React from 'react';

const GmailIcon = (props) => (
  <svg {...props} width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9711 9.85261V19.7485H24.0741C25.1208 19.7485 26 18.9099 26 17.8197V5.40784L19.9711 9.85261Z"
      fill="#3AA757"
    />
    <path
      d="M26 5.40786V3.3532L25.9582 2.17911C25.7907 0.963086 24.7021 0.250244 23.4042 0.250244C21.5202 0.250244 19.9711 1.88559 19.9711 3.73059V9.85264L26 5.40786Z"
      fill="#F9BB2D"
    />
    <path
      d="M6.02895 9.85261V19.7485H1.92592C0.837355 19.7905 0 18.9099 0 17.8616V5.40784L6.02895 9.85261Z"
      fill="#4688F1"
    />
    <path
      d="M0 5.40762V3.35296L0.0418677 2.17887C0.209339 0.962842 1.2979 0.25 2.55393 0.25C4.43798 0.25 5.98709 1.88534 5.98709 3.73034V9.8524L0 5.40762Z"
      fill="#C32527"
    />
    <path
      d="M12.8954 7.54644L5.02425 1.29859C5.6104 1.92757 5.98721 2.80814 5.98721 3.73064V9.85269L4.27063 8.59473L12.8954 15.1361L19.971 9.89462V3.77257C19.971 2.7662 20.3897 1.88564 21.0596 1.25666L21.4364 0.963135L12.8954 7.54644Z"
      fill="#FF3D00"
    />
  </svg>
);
export default GmailIcon;
