import * as React from 'react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { BrainLogoIcon, ExitIcon, TextLogoIcon } from '../icons';
import { SidebarLink } from '../index';
import grey from '../colors/grey';

const drawerWidthOpen = 235;
const drawerWidthClosed = 88;

const styles = ((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    overflowX: 'hidden',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    overflowX: 'hidden',
    width: drawerWidthOpen,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& $expandIcon': {
      '-moz-transform': 'scaleX(-1)', /* Gecko */
      '-o-transform': 'scaleX(-1)', /* Opera */
      '-webkit-transform': 'scaleX(-1)', /* Webkit */
      transform: 'scaleX(-1)', /* Standard */

      filter: 'FlipH', /* IE 6/7/8 */
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `${drawerWidthClosed}px`,
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
  },
  paper: {
    justifyContent: 'space-between',
    boxShadow: '2px 0px 20px rgba(0, 11, 114, 0.07)',
    border: 'none',
  },
  expandIcon: {
    transition: 'color 0.2s',
    color: grey[25],
    cursor: 'pointer',
    '&:hover': {
      color: grey[100],
    },
  },
  expandIconWrapper: {
    width: '30px',
    height: '49px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '30px',
    marginRight: '28px',
  },
  logoWrapperWrapper: {},
  logoWrapper: {
    cursor: 'pointer',
    marginBottom: '32px',
    display: 'flex',
    alignItems: 'center',
    height: '80px',
    justifyContent: 'center',
    boxShadow: '0px 2px 20px rgba(0, 36, 70, 0.07)',
    '$drawerClose &': {
      marginLeft: '-2px',
    },
    '$drawerOpen &': {},
  },
  partLogoIcon: {
    width: '30px',
  },
  fullLogoIcon: {
    height: '30px',
    marginLeft: '8px',
    marginTop: '3px',
    cursor: 'pointer',
    marginBottom: '3px',
  },
}));

const Sidebar = (props) => {
  const {
    classes,
    children,
    expanded,
    top,
    bottom,
    className,
    onClickLogo,
    location,
    onChangeOpen,
    history,
  } = props;
  const content = children;
  const [open, setOpen] = useState(expanded || false);
  useEffect(() => {
    if (onChangeOpen) {
      onChangeOpen(open);
    }
  }, [open]);
  const makeMenuItem = (menuItem, index) => <SidebarLink history={history} location={location} expandedHorizontally={open} {...menuItem} />;

  return (
    <div className={clsx(classes.root)}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(
            classes.paper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            },
          ),
        }}
      >
        <div>
          <div className={classes.logoWrapper}>
            <BrainLogoIcon className={classes.partLogoIcon} onClick={onClickLogo} />
            {open && <TextLogoIcon className={classes.fullLogoIcon} onClick={onClickLogo} />}
          </div>
          {top && top.map((menuItem, index) => makeMenuItem(menuItem, index))}
        </div>
        <div>
          {bottom && bottom.map((menuItem, index) => makeMenuItem(menuItem, index))}
          <Divider />
          <div className={classes.expandIconWrapper}>
            <ExitIcon
              className={classes.expandIcon}
              onClick={() => {
                setOpen(!open);
              }}
            />
          </div>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {content}
      </main>
    </div>
  );
};
export default withStyles(styles)(Sidebar);
