import React from 'react';

const FullWhiteLogoIcon = (props) => (
  <svg {...props} width="168" height="54" viewBox="0 0 168 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M84.7504 34.0653H80.2503L79.5003 37.5017H75.0003L80.2503 16.5016H84.7504L90.0004 37.5017H85.5004L84.7504 34.0653ZM83.8128 30.0562L83.2503 27.5744C83.0628 26.0471 82.6878 23.5653 82.3128 21.8471H82.1253C81.9378 23.5653 81.5628 26.0471 81.1878 27.5744L81.0003 30.0562H83.8128Z"
      fill="white"
    />
    <path d="M96.0004 16.5006V37.5008H91.5004V16.5006H96.0004Z" fill="white" />
    <path
      d="M100.501 32.1586H101.746C101.924 34.7376 103.348 36.2113 105.84 36.2113C108.331 36.2113 109.755 34.5534 109.755 32.3428C109.755 27.3691 100.678 27.1849 100.678 21.2901C100.678 18.5269 102.814 16.5006 105.662 16.5006C108.687 16.5006 110.645 18.5269 110.823 21.6585H109.399C109.221 19.0796 107.797 17.7901 105.662 17.7901C103.348 17.7901 101.924 19.2638 101.924 21.2901C101.924 26.2638 111.001 26.2638 111.001 32.3428C111.001 35.106 109.043 37.5008 105.84 37.5008C102.636 37.5008 100.678 35.4744 100.501 32.1586Z"
      fill="white"
    />
    <path
      d="M123.53 32.1553H116.824L115.412 37.5008H114L119.295 16.5006H120.706L126 37.5008H124.589L123.53 32.1553ZM123.177 31.0098L121.059 22.4188C120.706 20.8915 120.353 19.3643 120.177 18.4097C120 19.3643 119.648 20.8915 119.295 22.4188L117.177 31.0098H123.177Z"
      fill="white"
    />
    <path d="M139.5 36.1644V37.5008H129V16.5006H130.47V36.1644H139.5Z" fill="white" />
    <path
      d="M153.001 36.1644V37.5008H142.501V16.5006H152.803V17.6461H143.888V26.0461H152.208V27.3825H143.888V36.1644H153.001Z"
      fill="white"
    />
    <path
      d="M156.001 32.1586H157.424C157.628 34.7376 159.255 36.2113 162.102 36.2113C164.95 36.2113 166.577 34.5534 166.577 32.3428C166.577 27.3691 156.204 27.1849 156.204 21.2901C156.204 18.5269 158.645 16.5006 161.899 16.5006C165.357 16.5006 167.594 18.5269 167.797 21.6585H166.17C165.967 19.0796 164.34 17.7901 161.899 17.7901C159.255 17.7901 157.628 19.2638 157.628 21.2901C157.628 26.2638 168.001 26.2638 168.001 32.3428C168.001 35.106 165.763 37.5008 162.102 37.5008C158.441 37.5008 156.204 35.4744 156.001 32.1586Z"
      fill="white"
    />
    <path d="M42.3531 15.4574L48.4832 6.22309L24.52 0L25.8203 11.041L42.3531 15.4574Z" fill="white" />
    <path d="M34.9237 26.499L41.0538 17.064L26.0071 13.0491L26.9359 20.6774L34.9237 26.499Z" fill="white" />
    <path d="M6.87337 6.42243L20.9912 16.4597L23.9634 11.8425L22.6631 0.199341L6.87337 6.42243Z" fill="white" />
    <path d="M44.2106 15.8593L49.412 17.2645L49.7835 7.42798L44.2106 15.8593Z" fill="white" />
    <path d="M56.6556 21.078L51.4543 19.8735L52.0116 26.8996L56.6556 21.078Z" fill="white" />
    <path d="M51.6414 10.0372L51.2699 17.6655L57.5858 19.4722L51.6414 10.0372Z" fill="white" />
    <path
      d="M35.2934 29.1078L28.9776 38.7435L36.2223 43.1599L37.8941 44.1637L44.7673 35.7324L35.2934 29.1078Z"
      fill="white"
    />
    <path d="M24.3343 14.6543L22.4767 17.6655L24.8916 19.2714L24.3343 14.6543Z" fill="white" />
    <path d="M0.556915 18.6694L3.5291 28.9074L9.65923 29.7104L0.556915 18.6694Z" fill="white" />
    <path d="M29.7219 41.3531L36.2235 54.0001L39.0099 53.5986L36.9666 45.9703L29.7219 41.3531Z" fill="white" />
    <path
      d="M46.2544 36.9362L39.1954 45.5682L42.9106 48.9809L52.3845 45.5682L52.1987 41.1518L46.2544 36.9362Z"
      fill="white"
    />
    <path
      d="M25.2634 21.8812L21.5482 19.2715L11.8886 34.7288L18.3903 39.7475L27.121 38.3423L25.2634 21.8812Z"
      fill="white"
    />
    <path d="M52.1986 29.5089L53.1274 39.3454L60.0006 33.9253L58.5145 21.8806L52.1986 29.5089Z" fill="white" />
    <path d="M0 15.2557L12.446 30.1109L12.6318 30.3116L20.0623 18.0662L5.38709 7.62744L0 15.2557Z" fill="white" />
    <path
      d="M50.3407 28.9064L49.5977 19.2706L43.096 17.6647L36.4086 27.5012L45.8824 34.3265L50.3407 28.9064Z"
      fill="white"
    />
    <path d="M33.8076 28.1028L27.1202 23.2849L28.6063 35.7311L33.8076 28.1028Z" fill="white" />
    <path d="M50.7131 31.5161L47.5551 35.3303L51.2704 38.1407L50.7131 31.5161Z" fill="white" />
  </svg>
);
export default FullWhiteLogoIcon;
