import React from 'react';

export default (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.0002 13.3194C8.1752 13.3194 8.3502 13.3194 8.4377 13.2356C8.6127 13.0681 8.7002 12.9005 8.7002 12.5654C8.7002 11.7277 8.7002 10.9738 8.7002 10.1361V8.71204C8.7002 7.87434 8.7002 7.12042 8.7002 6.28272C8.7002 6.03141 8.7002 5.52879 8.0877 5.44502C7.9127 5.44502 7.7377 5.44502 7.6502 5.61256C7.4752 5.61256 7.3877 5.86387 7.3877 6.19895C7.3877 7.20419 7.3877 8.20942 7.3877 9.21466C7.3877 10.3037 7.3877 11.3927 7.3877 12.4817C7.3877 12.733 7.3877 13.2356 8.0002 13.3194Z" fill="#FF574F" />
    <path d="M4.76289 13.1518C4.85039 13.2356 5.02539 13.3194 5.11289 13.3194H5.20039C5.81289 13.2356 5.81289 12.733 5.81289 12.4817C5.81289 11.8115 5.81289 11.1414 5.81289 10.3874V8.46073C5.81289 7.7068 5.81289 6.95287 5.81289 6.19895C5.81289 5.86387 5.72539 5.61256 5.55039 5.52879C5.46289 5.44502 5.37539 5.36125 5.20039 5.36125C4.58789 5.44502 4.58789 5.94764 4.58789 6.19895C4.58789 7.20418 4.58789 8.12565 4.58789 9.13088C4.58789 10.2199 4.58789 11.3927 4.58789 12.4817C4.58789 12.8167 4.67539 13.0681 4.76289 13.1518Z" fill="#FF574F" />
    <path d="M15 3.26702C15 2.93194 14.7375 2.7644 14.3875 2.68063H14.3H14.2125H14.125H11.4125V2.51309C11.4125 2.34555 11.4125 2.17801 11.4125 2.09424C11.4125 0.837696 10.625 0 9.3125 0C8.35 0 7.475 0 6.6875 0C5.4625 0 4.5875 0.837696 4.5875 1.9267C4.5875 2.09424 4.5875 2.26178 4.5875 2.51309C4.5875 2.59686 4.5875 2.68063 4.5875 2.68063C3.7125 2.68063 2.75 2.68063 1.875 2.68063H1.7875C1.6125 2.68063 1.525 2.68063 1.4375 2.7644C1.0875 2.84817 1 3.09948 1 3.35079C1.0875 3.8534 1.4375 3.93717 1.7 3.93717H1.7875V7.03665C1.7875 9.29843 1.7875 11.5602 1.7875 13.822C1.7875 15.2461 2.575 16 4.0625 16C5.375 16 6.6875 16 8 16C9.3125 16 10.625 16 11.9375 16C13.5125 16 14.3 15.2461 14.3 13.7382C14.3 11.5602 14.3 9.3822 14.3 7.28796V4.02094H14.3875C15 3.8534 15 3.51832 15 3.26702ZM5.9 1.75916C5.9875 1.42408 6.25 1.17277 6.6 1.17277C7.7375 1.17277 8.6125 1.17277 9.4875 1.17277C9.925 1.17277 10.1875 1.42408 10.1875 1.84293C10.1875 2.01047 10.1875 2.26178 10.1875 2.42932C10.1875 2.51309 10.1875 2.59686 10.1875 2.59686H5.9C5.9 2.51309 5.9 2.42932 5.9 2.34555C5.8125 2.26178 5.8125 2.01047 5.9 1.75916ZM3.0125 13.9058C3.0125 11.0576 3.0125 8.20942 3.0125 5.36126V4.18848C3.0125 4.10471 3.0125 4.02094 3.0125 3.93717H12.9875C12.9875 4.02094 12.9875 4.10471 12.9875 4.18848C12.9875 4.27225 12.9875 4.27225 12.9875 4.35602C12.9875 6.19895 12.9875 8.04188 12.9875 9.80105C12.9875 11.1414 12.9875 12.3979 12.9875 13.7382C12.9875 14.5759 12.725 14.7435 11.9375 14.7435H11.4125C8.9625 14.7435 6.5125 14.7435 3.975 14.7435C3.275 14.7435 3.0125 14.5759 3.0125 13.9058Z" fill="#FF574F" />
    <path d="M10.8 13.3194C10.975 13.3194 11.15 13.3194 11.2375 13.1518C11.4125 12.9843 11.5 12.8167 11.5 12.4817C11.5 11.3927 11.5 10.2199 11.5 9.13088C11.5 8.12565 11.5 7.20418 11.5 6.19895C11.5 5.94764 11.5 5.44502 10.8875 5.36125C10.7125 5.36125 10.5375 5.36125 10.45 5.44502C10.275 5.61256 10.1875 5.7801 10.1875 6.11518C10.1875 6.78534 10.1875 7.53926 10.1875 8.20942V10.1361C10.1875 10.89 10.1875 11.644 10.1875 12.3979C10.1875 12.733 10.1875 13.2356 10.8 13.3194Z" fill="#FF574F" />
  </svg>
);
