import React from 'react';

const GoogleIcon = (props) => (
  <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.57164 12.0341L3.89287 14.568L1.41197 14.6205C0.670548 13.2453 0.25 11.6719 0.25 9.99995C0.25 8.38316 0.643202 6.85849 1.34018 5.51599H1.34071L3.54941 5.92092L4.51695 8.11636C4.31444 8.70674 4.20407 9.34049 4.20407 9.99995C4.20414 10.7157 4.33379 11.4014 4.57164 12.0341Z"
      fill="#FBBB00"
    />
    <path
      d="M19.5796 8.17847C19.6916 8.76827 19.7499 9.37738 19.7499 9.9999C19.7499 10.6979 19.6765 11.3788 19.5367 12.0356C19.0621 14.2707 17.8219 16.2223 16.1039 17.6033L16.1033 17.6028L13.3214 17.4609L12.9276 15.003C14.0676 14.3344 14.9585 13.2881 15.4278 12.0356H10.2142V8.17847H15.5039H19.5796Z"
      fill="#518EF8"
    />
    <path
      d="M16.1031 17.6028L16.1037 17.6034C14.4328 18.9464 12.3102 19.75 9.99971 19.75C6.28665 19.75 3.05843 17.6746 1.41162 14.6205L4.57129 12.0341C5.39467 14.2316 7.51451 15.7959 9.99971 15.7959C11.0679 15.7959 12.0687 15.5071 12.9274 15.003L16.1031 17.6028Z"
      fill="#28B446"
    />
    <path
      d="M16.2235 2.49452L13.0649 5.08042C12.1762 4.5249 11.1256 4.20398 10.0001 4.20398C7.45864 4.20398 5.29915 5.84005 4.51702 8.11635L1.34074 5.51598H1.34021C2.96291 2.38739 6.23185 0.249878 10.0001 0.249878C12.3658 0.249878 14.5349 1.09257 16.2235 2.49452Z"
      fill="#F14336"
    />
  </svg>
);
export default GoogleIcon;
