import React from 'react';
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
  <svg {...props} width="62" height="14" viewBox="0 0 62 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 11.7091H3.5L3 14H0L3.5 0H6.5L10 14H7L6.5 11.7091ZM5.875 9.03636L5.5 7.38182C5.375 6.36364 5.125 4.70909 4.875 3.56364H4.75C4.625 4.70909 4.375 6.36364 4.125 7.38182L4 9.03636H5.875Z"
      fill="#0486FE"
    />
    <path d="M14 0V14H11V0H14Z" fill="#0486FE" />
    <path
      d="M17 10.4386H17.8305C17.9492 12.1579 18.8983 13.1403 20.5593 13.1403C22.2203 13.1403 23.1695 12.0351 23.1695 10.5614C23.1695 7.24561 17.1186 7.12281 17.1186 3.19298C17.1186 1.35088 18.5424 0 20.4407 0C22.4576 0 23.7627 1.35088 23.8814 3.4386H22.9322C22.8136 1.7193 21.8644 0.859649 20.4407 0.859649C18.8983 0.859649 17.9492 1.84211 17.9492 3.19298C17.9492 6.50877 24 6.50877 24 10.5614C24 12.4035 22.6949 14 20.5593 14C18.4237 14 17.1186 12.6491 17 10.4386Z"
      fill="#2A2B2B"
    />
    <path
      d="M32.3529 10.4364H27.8824L26.9412 14H26L29.5294 0H30.4706L34 14H33.0588L32.3529 10.4364ZM32.1176 9.67273L30.7059 3.94545C30.4706 2.92727 30.2353 1.90909 30.1177 1.27273C30 1.90909 29.7647 2.92727 29.5294 3.94545L28.1176 9.67273H32.1176Z"
      fill="#2A2B2B"
    />
    <path d="M43 13.1091V14H36V0H36.98V13.1091H43Z" fill="#2A2B2B" />
    <path d="M52 13.1091V14H45V0H51.8679V0.763636H45.9245V6.36364H51.4717V7.25455H45.9245V13.1091H52Z" fill="#2A2B2B" />
    <path
      d="M54 10.4386H54.9491C55.0847 12.1579 56.1695 13.1403 58.0678 13.1403C59.9661 13.1403 61.0508 12.0351 61.0508 10.5614C61.0508 7.24561 54.1356 7.12281 54.1356 3.19298C54.1356 1.35088 55.7627 0 57.9322 0C60.2373 0 61.7288 1.35088 61.8644 3.4386H60.7797C60.6441 1.7193 59.5593 0.859649 57.9322 0.859649C56.1695 0.859649 55.0847 1.84211 55.0847 3.19298C55.0847 6.50877 62 6.50877 62 10.5614C62 12.4035 60.5085 14 58.0678 14C55.6271 14 54.1356 12.6491 54 10.4386Z"
      fill="#2A2B2B"
    />
  </svg>
);
