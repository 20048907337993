import React from 'react';

const ChatIcon = (props) => (
  <svg {...props}>
    <path
      d="M31.7664 11.2969C31.7664 8.43443 30.0961 5.68919 27.1231 3.66511C24.15 1.64102 20.1176 0.503906 15.913 0.503906C11.7084 0.503906 7.67602 1.64102 4.70293 3.66511C1.72984 5.68919 0.0595703 8.43443 0.0595703 11.2969C0.0595703 14.1594 1.72984 16.9046 4.70293 18.9287C7.67602 20.9528 11.7084 22.0899 15.913 22.0899H17.8947C19.8763 23.8888 23.8397 25.6876 27.8031 25.6876C26.8122 24.7882 25.8214 22.0899 25.8214 19.729C27.6778 18.717 29.1761 17.4338 30.2052 15.9741C31.2343 14.5145 31.7679 12.916 31.7664 11.2969Z"
      fill="currentColor"
    />
  </svg>
);
export default ChatIcon;
