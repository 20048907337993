import React from 'react';

export default (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#00DD9B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5552 7.73909C18.0663 8.11546 18.1491 8.80196 17.7402 9.27243L11.8144 16.0906C11.592 16.3464 11.2566 16.4967 10.9007 16.4999C10.5449 16.5032 10.2062 16.3591 9.97841 16.1075L6.27473 12.0166C5.85569 11.5537 5.92363 10.8659 6.42648 10.4801C6.92932 10.0944 7.67665 10.157 8.09569 10.6198L10.8702 13.6844L15.8893 7.90946C16.2982 7.439 17.044 7.36272 17.5552 7.73909Z"
      fill="#00DD9B"
    />
  </svg>
);
