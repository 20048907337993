import React from 'react';
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
  <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30 20.3027C30 16.5799 27.8643 13.2575 24.6657 11.6414C24.5663 18.7793 18.7793 24.5663 11.6414 24.6657C13.2575 27.8643 16.5799 30 20.3027 30C22.0482 30 23.7455 29.5351 25.2369 28.6519L29.9576 29.9576L28.6519 25.2369C29.5351 23.7455 30 22.0482 30 20.3027Z"
      fill="#B4DBFF"
    />
    <path
      d="M22.9102 11.4551C22.9102 5.13863 17.7715 0 11.4551 0C5.13863 0 0 5.13863 0 11.4551C0 13.5136 0.547943 15.518 1.58844 17.2776L0.0421143 22.8678L5.63255 21.3217C7.3922 22.3622 9.39651 22.9102 11.4551 22.9102C17.7715 22.9102 22.9102 17.7715 22.9102 11.4551ZM9.69727 8.78906H7.93945C7.93945 6.85043 9.51645 5.27344 11.4551 5.27344C13.3937 5.27344 14.9707 6.85043 14.9707 8.78906C14.9707 9.77303 14.5541 10.7188 13.8274 11.3834L12.334 12.7503V14.1211H10.5762V11.9762L12.6407 10.0866C13.0096 9.74899 13.2129 9.28825 13.2129 8.78906C13.2129 7.81975 12.4244 7.03125 11.4551 7.03125C10.4858 7.03125 9.69727 7.81975 9.69727 8.78906ZM10.5762 15.8789H12.334V17.6367H10.5762V15.8789Z"
      fill="#0486FE"
    />
  </svg>
);
